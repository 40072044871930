import { type VariantProps, cva } from "class-variance-authority";

export const chipVariants = cva(
  "inline-flex items-center border whitespace-nowrap px-3 py-2 text-xs",
  {
    variants: {
      variant: {
        default: "rounded-full",
        tile: "rounded-none",
      },
      color: {
        default: "bg-white border-primary text-primary",
        secondary: "bg-off-white-30 border-sand-80 text-sand-80",
      },
    },
    defaultVariants: {
      variant: "default",
      color: "default",
    },
  },
);

export type ChipVariants = VariantProps<typeof chipVariants>;
